<template>
  <div class="AcceptAnalysisModal">
    <span class="Title">{{ mixWB('ADD_ANALYSIS') }}</span>

    <p
      v-for="text in texts"
      :key="text"
      v-html="text"/>

    <div class="ButtonsWrap">
      <Button
        :text="mixWB('NEJ')"
        color="grey"
        :outlined="true"
        @button-click="onNoClick" />
      <Button
        :text="mixWB('YES')"
        :isLoading="isYesLoading"
        @button-click="onYesClick" />
    </div>
  </div>
</template>

<script>
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'AcceptAnalysisModal',
  props: {
    texts: {
      type: Array,
      required: true,
    },
    sample: {
      type: Object,
      required: true,
    },
    sampleTypeID: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      isYesLoading: false,
    }
  },
  methods: {
    onNoClick() {
      this.$emit('pass-to-parent', {
        name: 'no-click',
      })
    },
    onYesClick() {
      this.isYesLoading = true
      this.$emit('pass-to-parent', {
        name: 'yes-click',
        value: {
          sample: this.sample,
          sampleTypeID: this.sampleTypeID,
        },
      })
    },
  },
  components: { Button },
}
</script>

<style lang="stylus" scoped>
  .AcceptAnalysisModal
    display block

  .Title
    modalTitle()

  .ButtonsWrap
    border-top 1px solid $color_grey_lighter
    padding-top 20px
    margin-top 20px
    display flex
    justify-content space-between
    .ButtonWrap
      min-width 100px
      margin-bottom 0
</style>
