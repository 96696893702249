<template>
  <tr>
    <td v-if="finalData.isCompleted">
      <div class="IconWrap Completed">
        <CheckIcon />
      </div>
    </td>
    <td v-else>
      <div class="IconWrap Uncompleted">
        <WarningIcon />
      </div>
    </td>
    <template
      v-for="column in finalData.columns">
      <td
        :key="column.text"
        :class="column.classes"
        v-if="column.isActive">
        <span
          v-for="text in column.texts"
          :key="text"
          :class="{ AllowMultiline: column.texts.length === 1 }"
          v-html="text"/>
        <span
          class="AcceptOption"
          v-if="column.isUnintentional"
          @click="onAccceptClick(column.sampleTypeID)"
          v-html="mixWB('ADD_ANALYSIS')"/>
      </td>
    </template>
  </tr>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'

export default {
  name: 'StandardSampleRow',
  props: {
    finalData: {
      type: Object,
      required: true,
    },
  },
  methods: {
    onAccceptClick(sampleTypeID) {
      this.$emit('accept-analysis', { sample: this.finalData.sample, sampleTypeID })
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .IconWrap
    box(40px)
    padding 7.5px
    margin 0 auto
    &.Completed
      padding 9px
      svg
        fill $color_primary
    &.Uncompleted
      svg
        fill $color_warning
  span
    white-space nowrap
    &.AllowMultiline
      white-space pre-wrap
  .SampleDetails
    span
      white-space pre-wrap
      &:nth-child(2)
        color $color_grey

  .AcceptOption
    cursor pointer
    text-decoration underline
    margin-top 10px
    white-space normal
    padding 5px
    border-radius 3px
    background-color rgba(#000, 10%)
    transition background-color 0.15s ease
    &:hover
      background-color rgba(#000, 20%)
</style>
