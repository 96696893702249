<template>
  <tr>
    <td v-if="sampleItem.isCompleted">
      <div class="IconWrap Completed">
        <CheckIcon />
      </div>
    </td>
    <td v-else>
      <div class="IconWrap Uncompleted">
        <WarningIcon />
      </div>
    </td>
    <td>T{{ sampleItem.equivalentNumber }}</td>
    <td>P{{ sampleItem.equivalentSampleNumber }}</td>
    <td>{{ resultText }}</td>
  </tr>
</template>

<script>
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'

export default {
  name: 'ExternalAnalysesStatusRow',
  props: {
    sampleItem: {
      type: Object,
      required: true,
    },
  },
  computed: {
    resultText() {
      const { result } = this.sampleItem
      if (result === 'ok') {
        return this.mixWB('ANALYSES_ARE_READY')
      }

      if (result === 'not-found') {
        return this.mixWB('THE_SAMPLE_IS_NOT_FOUND_IN_THE_PROJECT')
      }

      return this.mixWB('STATUS_AWAITING_TEST_RESULTS')
    },
  },
  components: {
    CheckIcon,
    WarningIcon,
  },
}
</script>

<style lang="stylus" scoped>
  .IconWrap
    box(40px)
    padding 7.5px
    margin 0 auto
    &.Completed
      padding 9px
      svg
        fill $color_primary
    &.Uncompleted
      svg
        fill $color_warning
</style>
