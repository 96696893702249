<template>
  <div class="AnalysesStatus">
    <SectionHeadline :text="mixWB('STATUS_OF_ANALYSES')" />

    <div class="InnerWrap">
      <!-- General analyzes section -->
      <div class="InfoText">
        <span>{{ mixWB('OVERVIEW_OF_THE_CURRENT_ANALYSES_IN_THE_PROJECT') }}</span>
      </div>

      <div class="TableWrap">
        <table>
          <tr>
            <th/>
            <th>{{ mixWB('REQUIRED') }}</th>
            <th>{{ mixWB('MISSING') }}</th>
            <th>{{ mixWB('PERFORMED') }}</th>
            <th>{{ mixWB('UNINTENTIONAL') }}</th>
          </tr>
          <template v-for="summaryAnalysis in standardSamplesStatus.summary">
            <AnalysisStatusRow
              :summaryAnalysis="summaryAnalysis"
              :showRow="standardSamplesStatus.usedAnalysisIDs.includes(
                summaryAnalysis.sampleTypeID,
              )"
              :key="summaryAnalysis.sampleTypeID"
              v-if="!['cpScreening', 'ecoTox'].includes(summaryAnalysis.sampleTypeID)" />
          </template>
          <tr class="Total">
            <td>{{ mixWB('TOTAL') }}</td>
            <td>{{ standardSamplesStatus.totals.required }}</td>
            <td :class="{ Missing: standardSamplesStatus.totals.missing }">
              {{ standardSamplesStatus.totals.missing }}
            </td>
            <td>{{ standardSamplesStatus.totals.complete }}</td>
            <td :class="{ Unintentional: standardSamplesStatus.totals.unintentional }">
              {{ standardSamplesStatus.totals.unintentional }}
            </td>
          </tr>
        </table>
      </div>

      <!-- CP screening section -->
      <div
        v-if="cpScreeningSummaryAnalysis"
        class="InfoText">
        <span>{{ mixWB('CURRENT_CP_SCREENINGS_IN_THE_PROJECT') }}</span>
      </div>

      <div
        class="TableWrap"
        v-if="cpScreeningSummaryAnalysis">
        <table
          class="StatusTable">
          <tr>
            <th/>
            <th>{{ mixWB('REQUIRED') }}</th>
            <th>{{ mixWB('MISSING') }}</th>
            <th>{{ mixWB('PERFORMED') }}</th>
            <th>{{ mixWB('UNINTENTIONAL') }}</th>
          </tr>
          <AnalysisStatusRow
            :summaryAnalysis="cpScreeningSummaryAnalysis"
            :showRow="true" />
        </table>
      </div>

      <!-- Status -->
      <span
        class="StatusText"
        v-html="status.text"/>

      <p
        class="StatusInfoText"
        v-if="status.infoText">
        {{ status.infoText }}
      </p>
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import AnalysisStatusRow from '@/components/ResultItems/AnalysisStatusRow.vue'
import { mapGetters } from 'vuex'

export default {
  name: 'AnalysesStatus',
  computed: {
    ...mapGetters([
      'standardSamplesStatus',
    ]),
    totalRowCompleteClass() {
      return this.standardSamplesStatus.totals.required.length
        && !this.standardSamplesStatus.totals.missing.length
        && this.standardSamplesStatus.totals.complete.length
        && !this.standardSamplesStatus.totals.unintentional.length
        ? 'Complete' : ''
    },
    cpScreeningSummaryAnalysis() {
      const cpScreeningSummaryAnalysis = this.standardSamplesStatus.summary.find(
        (x) => x.sampleTypeID === 'cpScreening',
      )

      const isUsed = cpScreeningSummaryAnalysis.status.complete.length
        || cpScreeningSummaryAnalysis.status.missing.length
        || cpScreeningSummaryAnalysis.status.required.length
        || cpScreeningSummaryAnalysis.status.unintentional.length

      return isUsed ? cpScreeningSummaryAnalysis : null
    },
    status() {
      const { totals } = this.standardSamplesStatus
      const cpScreeningSummaryAnalysis = this.standardSamplesStatus.summary.find(
        (x) => x.sampleTypeID === 'cpScreening',
      )

      const status = {
        status: '',
        text: `<b>${ this.mixWB('STATUS') }:</b> `,
        infoText: '',
      }

      // No analyzes required
      if (!totals.required && !cpScreeningSummaryAnalysis.status.required.length) {
        status.status = 'nothing-required'
        status.text += this.mixWB('NO_ANALYZES_ARE_NEEDED')
        return status
      }

      // Has missing analyzes
      if (totals.missing || cpScreeningSummaryAnalysis.status.missing.length) {
        status.status = 'missing'
        status.text += this.mixWB('THERE_ARE_ANALYZES_THAT_ARE_MISSING')
        status.infoText = this.mixWB('REPORT_NOT_READY_UNTIL_ANALYSES_HAS_RESULTS')
        return status
      }

      // Has unintentional analyzes
      if (totals.unintentional || cpScreeningSummaryAnalysis.status.unintentional.length) {
        status.status = 'unintentional'
        status.text += this.mixWB('THERE_ARE_UNINTENTIONAL_ANALYZES')
        status.infoText = this.mixWB('UNINTENTIONALLY_UPLOAD_AGAIN_EXPLANATION_TEXT')
        return status
      }

      // All analyzes are completed
      status.status = 'complete'
      status.text += this.mixWB('ALL_REQUIRED_ANALYSES_HAVE_BEEN_PERFORMED')

      return status
    },
  },
  components: {
    SectionHeadline,
    AnalysisStatusRow,
  },

}
</script>

<style lang="stylus" scoped>
  .AnalysesStatus
    width 100%
    margin-bottom 50px

  .InnerWrap
    width 100%
    max-width 600px

  .InfoText
    margin-bottom 10px

  .TableWrap
    width 100%
    margin-bottom 20px
    +below($tablet)
      overflow-x auto

  table
    width 100%
    min-width 500px

  .StatusInfoText
    font-style italic
    margin-top 5px
</style>

<style lang="stylus">
  .AnalysesStatus
    table,
    tr,
    th,
    td
      border-collapse collapse
      border 1px solid $color_grey_light

    th,
    td
      padding 5px
      text-align center
      height 30px
      &:first-child
        text-align left
        width 160px

    th
      background-color $color_grey_lighter

    tr
      &:nth-child(odd)
        background-color $color_grey_lightest
      &.Total
        border-top-width 2px
        td
          font-weight bold

    .Missing
      background-color $color_blue_lightest !important
    .Unintentional
      background-color $color_blue_lightest !important
    .Complete
      background-color lighten($color_primary_lightest, 50%) !important

</style>
