<template>
  <tr class="HeaderRow">
    <template
      v-for="column in columns">
      <th
        :class="column.class"
        :key="column.text"
        v-if="column.isActive"
        v-html="column.text"/>
    </template>
  </tr>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'HeaderRow',
  computed: {
    ...mapGetters([
      'standardSamplesStatus',
    ]),
    columns() {
      const columns = []

      // Add status
      columns.push({
        text: this.mixWB('STATUS'),
        class: 'Status',
        isActive: true,
      })

      // Add sample ID
      columns.push({
        text: this.mixWB('SAMPLE_NUMBER_SHORT'),
        class: 'SampleID',
        isActive: true,
      })

      // Add sample details
      columns.push({
        text: this.mixWB('TYPE'),
        class: 'SampleDetails',
        isActive: true,
      })

      // Add analyses
      this.standardSamplesStatus.summary.forEach((analysis) => {
        // Add analysis
        const analysisItem = {
          text: this.mixWB(analysis.translation),
          class: 'SampleTitle',
          isActive: this.standardSamplesStatus.usedAnalysisIDs.includes(analysis.sampleTypeID),
        }

        if (analysis.sampleTypeID === 'cpScreening') {
          analysisItem.class += ' CPScreening'
        }

        columns.push(analysisItem)
      })

      return columns
    },
  },

}
</script>

<style lang="stylus" scoped>
  .HeaderRow
    background-color $color_grey_lighter

  .Status
    width 40px
    text-align left
  .SampleID
    width 50px
    text-align left
  .SampleDetails
    text-align left
    min-width 200px
  .SampleTitle
    width 80px
</style>
