<template>
  <div class="TestDataUploadStatusModal">
    <div
      v-if="uploadStatus === 'upload-complete'"
      class="CompleteWrap">
      <CheckIcon />
    </div>
    <div
      v-else-if="uploadStatus.includes('error-')"
      class="ErrorWrap">
      <WarningIcon />
    </div>
    <div
      v-else
      class="ProgressAnimationWrap">
      <Circular size="medium" />
    </div>
    <span
      v-if="uploadStatus === 'converting-file'"
      class="ProgressText">{{ mixWB('CONVERTING_FILE') }}</span>
    <span
      v-else-if="uploadStatus === 'generating-results'"
      class="ProgressText">{{ mixWB('PROCESSING_DATA') }}</span>
    <span
      v-else-if="uploadStatus === 'uploading-results'"
      class="ProgressText">{{ mixWB('UPLOADING_TEST_RESULTS') }}</span>
    <span
      v-else-if="uploadStatus === 'upload-complete'"
      class="ProgressText">{{ mixWB('UPLOAD_COMPLETE') }}</span>
    <span
      v-else-if="uploadStatus === 'error-converting'"
      class="ProgressText">{{ mixWB('COULD_NOT_PROCESS_FILE') }}</span>
    <span
      v-else-if="uploadStatus === 'error-wrong-case-number'"
      class="ProgressText">{{ mixWB('NOT_THE_SAME_CASE_NUMBER') }}</span>
    <span
      v-else-if="uploadStatus === 'error-upload-error'"
      class="ProgressText">{{ mixWB('UNABLE_TO_UPLOAD_TEST_RESULTS_TEXT') }}</span>
    <span
      v-else-if="uploadStatus === 'error-extract-data'"
      class="ProgressText">{{ mixWB('UNABLE_TO_EXTRACT_DATA') }}</span>
    <span
      v-else-if="uploadStatus.includes('error-')"
      class="ProgressText">{{ mixWB('UNKNOWN_ERROR') }}</span>

    <div
      v-if="showCloseButton"
      class="ButtonWrap">
      <Button
        :text="mixWB('CLOSE')"
        @button-click="onButtonClick" />
    </div>
  </div>
</template>

<script>
import Circular from '@/components/Progress/Circular'
import CheckIcon from '@/assets/svg/check.svg?inline'
import WarningIcon from '@/assets/svg/warning.svg?inline'
import Button from '@/components/Buttons/Button'

export default {
  name: 'TestDataUploadStatusModal',
  props: {
    uploadStatus: {
      type: String,
      required: true,
    },
  },
  computed: {
    showCloseButton() {
      if (this.uploadStatus.includes('error-')) {
        return true
      }

      if (this.uploadStatus === 'upload-complete') {
        return true
      }

      return false
    },
  },
  methods: {
    onButtonClick() {
      this.$emit('close-dialog')
    },
  },
  components: {
    Circular,
    CheckIcon,
    WarningIcon,
    Button,
  },
}
</script>

<style lang="stylus" scoped>
  .TestDataUploadStatusModal
    display block
    height 260px
    flex-center-children-column()
    justify-content center

  .ProgressAnimationWrap
    position relative
    box(60px)
    margin-bottom 20px

  .CompleteWrap
    box(60px)
    margin-bottom 10px
    padding 5px
    svg
      fill $color_primary

  .ErrorWrap
    box(60px)
    margin-bottom 10px
    padding 5px
    svg
      fill $color_warning

  .ProgressText
    font-size 1.25rem
    margin-bottom 50px
    text-align center
</style>
