<template>
  <div
    class="StandardSamplesTable"
    v-if="standardSamplesStatus.list.length">
    <SectionHeadline
      :text="`${ mixWB('SAMPLES_TAKEN') } (${ standardSamplesStatus.list.length })`"/>

    <div class="InfoText">
      <span>{{ mixWB('STATUS_OF_ALL_SAMPLES_FOR_THIS_PROJECT') }}</span>
    </div>

    <div class="TableWrap">
      <table>
        <HeaderRow />
        <StandardSampleRow
          v-for="sampleItem in standardSamplesStatus.list"
          :key="sampleItem.sampleNumber"
          :sampleItem="sampleItem"
          :finalData="getFinalData(sampleItem)"
          @accept-analysis="onAcceptAnalysesClick" />
      </table>
    </div>

    <Dialog
      :isShowing="showAcceptAnalysisModal"
      :useComponent="AcceptAnalysisModal"
      :componentProps="acceptAnalysisModalProps"
      size="small"
      :easyClose="false"
      @no-click="onNoClick"
      @yes-click="onYesClick"
      @close="showAcceptAnalysisModal = false" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import HeaderRow from '@/components/ResultItems/StandardSamplesTable/HeaderRow'
import { mapGetters } from 'vuex'
import StandardSampleRow from '@/components/ResultItems/StandardSamplesTable/StandardSampleRow'
import AcceptAnalysisModal from '@/components/ResultItems/StandardSamplesTable/AcceptAnalysisModal'
import Dialog from '@/components/Modals/Dialog.vue'
import Type from '@/globals/javascript/models/Type'
import { union } from 'lodash-es'

export default {
  name: 'StandardSamplesTable',
  data() {
    return {
      // Accept analysis modal
      showAcceptAnalysisModal: false,
      AcceptAnalysisModal,
      acceptAnalysisModalProps: {
        texts: [],
        sample: null,
        sampleTypeID: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'standardSamplesStatus',
      'screeningSamples',
      'screeningTypes',
      'sampleTypesAsArray',
    ]),
  },
  methods: {
    getSample(sampleNumber) {
      return this.screeningSamples.find(
        (x) => x.sampleNumber === sampleNumber,
      )
    },
    getFinalData(sampleItem) {
      const sample = this.getSample(sampleItem.sampleNumber)

      const finalData = {
        isCompleted: sampleItem.isCompleted,
        columns: [],
        sample,
      }

      // Add sample id
      finalData.columns.push({
        texts: [sample.getNumber()],
        classes: [this.getResultClass(sampleItem.result)],
        isActive: true,
      })

      // Add sample details
      finalData.columns.push({
        texts: [sample.getFullTitle(), sample.getKindOfSampleText()],
        classes: ['SampleDetails'],
        isActive: true,
      })

      // Add each analyses
      sampleItem.analyses.forEach((analysis) => {
        finalData.columns.push(this.getAnalysisColumnItem(sampleItem, analysis))
      })

      return finalData
    },
    getResultClass(result) {
      if (result === 0) {
        return 'Clean'
      }
      if (result === 1) {
        return 'Contaminated'
      }
      if (result === 2) {
        return 'Hazardous'
      }

      return ''
    },
    getAnalysisColumnItem(sampleItem, analysis) {
      const columnItem = {
        texts: [],
        classes: [],
        isActive: this.standardSamplesStatus.usedAnalysisIDs.includes(
          analysis.sampleTypeID,
        ),
        isUnintentional: false,
        sampleTypeID: analysis.sampleTypeID,
      }

      // Add class for CP screening
      if (analysis.sampleTypeID === 'cpScreening') {
        // Add notice class for cp screening
        columnItem.classes.push('CPScreening')
      }

      // Missing
      if (analysis.status.missing) {
        columnItem.texts.push(this.mixWB('MISSING'))
        columnItem.classes.push('Missing')
        return columnItem
      }

      // Unintentional
      if (analysis.status.unintentional) {
        columnItem.texts.push(this.mixWB('UNINTENTIONAL'))
        columnItem.classes.push('Unintentional')
        columnItem.isUnintentional = true
        return columnItem
      }

      // Not analysed
      if (analysis.result === null) {
        columnItem.texts.push(this.mixWB('NOT_ANALYZED_SHORT'))
        columnItem.classes.push('NotAnalysed')
        return columnItem
      }

      // Add text for CP screening
      if (analysis.sampleTypeID === 'cpScreening') {
        // Add text
        const testListResult = sampleItem.analysisTestListResults.find((x) => x.id === 'cpScreening')

        if (testListResult.result === null) {
          columnItem.texts.push(this.mixWB('NOT_ANALYZED_SHORT'))
        }
        else {
          columnItem.texts.push(
            testListResult.options.isProven ? this.mixWB('PROVEN') : this.mixWB('NOT_PROVEN'),
          )
        }
        return columnItem
      }

      // Completed
      // - Add result color class
      if (analysis.result === 0) {
        columnItem.classes.push('Clean')
      }
      if (analysis.result === 1) {
        columnItem.classes.push('Contaminated')
      }
      if (analysis.result === 2) {
        columnItem.classes.push('Hazardous')
      }

      // Add test result values
      analysis.requiredTestIDs.forEach((testID) => {
        const testListResult = sampleItem.analysisTestListResults.find((x) => x.id === testID)

        if (testListResult.testType === 'prove-based') {
          columnItem.texts.push(
            testListResult.options.isProven ? `<b>${ this.mixWB('PROVEN') }</b>` : this.mixWB('NOT_PROVEN'),
          )
        }
        else {
          const title = analysis.requiredTestIDs.length === 1
            ? ''
            : `${ this.mixWB(testListResult.translation) }: `

          let value = ''
          if (testListResult.options.isTooLowToMeasure) {
            value = this.mixWB('BELOW_THE_LIMIT')
          }
          else {
            if (testListResult.options.isBelowValue) {
              value = '< '
            }
            else if (testListResult.options.isAboveValue) {
              value = '> '
            }
            value += this.mixFormatNumber(testListResult.value, 2)
          }

          const text = `${ title || '' } ${ value }`
          if (testListResult.result === 2) {
            columnItem.texts.push(`<b>${ text }</b>`)
          }
          else {
            columnItem.texts.push(text)
          }
        }
      })

      return columnItem
    },
    onAcceptAnalysesClick({ sample, sampleTypeID }) {
      const sampleType = this.sampleTypesAsArray.find((x) => x.id === sampleTypeID)
      const sampleTypeTitle = this.mixWB(sampleType.translation)

      const texts = []

      // Add intro text
      texts.push(this.mixWB(
        'X_HAS_UNINTENTIONAL_ANALYSIS_FOR_Y',
        [sample.getNumber(), sampleTypeTitle],
      ))

      // Add warning text for changes on equivalent sample
      const equivalentSamples = this.screeningSamples.filter(
        (x) => !x.otherScreeningID && x.equivalentSampleNumber === sample.sampleNumber,
      )

      if (equivalentSamples.length) {
        const tNumbers = equivalentSamples.map((x) => `T${ x.equivalentNumber }`).join(', ')

        texts.push(`${
          this.mixWB('THIS_SAMPLE_IS_REPRESENTATIVE_OF_X', [tNumbers])
        } ${
          this.mixWB('CHANGES_WILL_X_ALSO_RECEIVE', [tNumbers])
        }`)
      }

      // Add final text
      texts.push(this.mixWB(
        'ADD_ANALYSIS_TO_SAMPLE_TEXT',
      ))

      // Show modal
      this.acceptAnalysisModalProps.texts = texts
      this.acceptAnalysisModalProps.sample = sample
      this.acceptAnalysisModalProps.sampleTypeID = sampleTypeID
      this.showAcceptAnalysisModal = true
    },
    onNoClick() {
      this.showAcceptAnalysisModal = false
    },
    async onYesClick({ sample, sampleTypeID }) {
      // Add analysis to original type
      const type = this.screeningTypes.find((x) => x.id === sample.typeID)
      const typeCopy = new Type({ ...type })
      const {
        samples,
        coating,
        dust,
      } = typeCopy

      if (sample.kindOfSample === 'material') {
        samples.manualSampleIDs = union(samples.manualSampleIDs, [sampleTypeID])
      }
      if (sample.kindOfSample === 'coating') {
        coating.samples.manualSampleIDs = union(coating.samples.manualSampleIDs, [sampleTypeID])
      }
      if (sample.kindOfSample === 'dust') {
        dust.samples.manualSampleIDs = union(dust.samples.manualSampleIDs, [sampleTypeID])
      }

      this.$store.dispatch('setTypeProps', {
        typeID: type.id,
        props: {
          samples,
          coating,
          dust,
        },
        updateScreening: false,
      })

      // Update affected samples
      this.$store.dispatch('updateAffectedSamples', typeCopy)

      // Force store update
      await this.$store.dispatch('forceUpdateScreeningCollections')

      // Update screening
      this.$store.dispatch('updateScreening', { updateTestResultStatus: true })

      // Hide modal
      this.showAcceptAnalysisModal = false
    },
  },
  components: {
    SectionHeadline,
    HeaderRow,
    StandardSampleRow,
    Dialog,
  },
}
</script>

<style lang="stylus" scoped>
  .StandardSamplesTable
    width 100%
    max-width 1200px
    margin-bottom 50px

  .InfoText
    margin-bottom 10px

  .TableWrap
    width 100%
    margin-bottom 20px
    overflow-x auto

  table
    width 100%
    min-width 800px

</style>

<style lang="stylus">
  .StandardSamplesTable
    table,
    tr,
    th,
    td
      border-collapse collapse
      border 1px solid $color_grey_light
      font-size 0.875rem

    th,
    td
      padding 5px
      text-align center
      &:first-child
        text-align left
      &:nth-child(2)
        text-align left
      &:nth-child(3)
        text-align left

    table
      width 100%

    .Missing
      background-color $color_blue_lightest !important
    .Unintentional
      background-color $color_blue_lightest !important
    .Complete
      background-color lighten($color_primary_lightest, 50%) !important
    .Clean
      background-color $color_online_green !important
    .Contaminated
      background-color $color_online_yellow !important
    .Hazardous
      background-color $color_online_red !important
    .CPScreening
      border-left-width 5px
      border-right-width 5px
      span
        font-style italic
        color $color_grey
</style>
