<template>
  <tr
    v-if="showRow"
    class="AnalysisStatusRow"
    :class="completeClass">
    <td>{{ mixWB(summaryAnalysis.translation) }}</td>

    <!-- Required -->
    <td>
      <span>{{ summaryAnalysis.status.required.length }}</span>
      <div
        class="ToolTipWrap"
        v-if="requiredSamples.length">
        <ToolTip
          :texts="requiredSamples"
          iconSize="small"
          overlaySize="small" />
      </div>
    </td>

    <!-- Missing -->
    <td :class="missingClass">
      <span>{{ summaryAnalysis.status.missing.length }}</span>
      <div
        class="ToolTipWrap"
        v-if="missingSamples.length">
        <ToolTip
          :texts="missingSamples"
          iconSize="small"
          overlaySize="small" />
      </div>
    </td>

    <!-- Complete -->
    <td>
      <span>{{ summaryAnalysis.status.complete.length }}</span>
      <div
        class="ToolTipWrap"
        v-if="completeSamples.length">
        <ToolTip
          :texts="completeSamples"
          iconSize="small"
          overlaySize="small" />
      </div>
    </td>

    <!-- Unintentional -->
    <td :class="unintentionalClass">
      <span>{{ summaryAnalysis.status.unintentional.length }}</span>
      <div
        class="ToolTipWrap"
        v-if="unintentionalSamples.length">
        <ToolTip
          :texts="unintentionalSamples"
          iconSize="small"
          overlaySize="small" />
      </div>
    </td>
  </tr>
</template>

<script>
import ToolTip from '@/components/ToolTip.vue'

export default {
  name: 'AnalysisStatusRow',
  props: {
    summaryAnalysis: {
      type: Object,
      required: true,
    },
    showRow: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    requiredSamples() {
      const list = this.summaryAnalysis.status.required.map((x) => `P${ x }`)

      return list.length ? [`${ this.mixWB('SAMPLES_2') }: ${ list.join(', ') }`] : []
    },
    missingSamples() {
      const list = this.summaryAnalysis.status.missing.map((x) => `P${ x }`)

      return list.length ? [`${ this.mixWB('SAMPLES_2') }: ${ list.join(', ') }`] : []
    },
    completeSamples() {
      const list = this.summaryAnalysis.status.complete.map((x) => `P${ x }`)

      return list.length ? [`${ this.mixWB('SAMPLES_2') }: ${ list.join(', ') }`] : []
    },
    unintentionalSamples() {
      const list = this.summaryAnalysis.status.unintentional.map((x) => `P${ x }`)

      return list.length ? [`${ this.mixWB('SAMPLES_2') }: ${ list.join(', ') }`] : []
    },
    missingClass() {
      return this.summaryAnalysis.status.missing.length ? 'Missing' : ''
    },
    completeClass() {
      return this.summaryAnalysis.status.required.length
        && !this.summaryAnalysis.status.missing.length
        && this.summaryAnalysis.status.complete.length
        && !this.summaryAnalysis.status.unintentional.length
        ? 'Complete' : ''
    },
    unintentionalClass() {
      return this.summaryAnalysis.status.unintentional.length ? 'Unintentional' : ''
    },
  },
  components: { ToolTip },
}
</script>

<style lang="stylus" scoped>
  .AnalysisStatusRow
    td
      position relative
    .ToolTipWrap
      position absolute
      top 0
      right 0
</style>
