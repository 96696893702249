<template>
  <div class="Samples">
    <Header />

    <div class="PageContentWrap">
      <div class="InnerWrap">
        <AnalysesUploadSection />

        <AnalysesStatus />

        <ExternalAnalysesStatus />

        <StandardSamplesTable />
      </div>
    </div>
  </div>
</template>

<script>
import Header from '@/components/ResultItems/Header.vue'
import AnalysesStatus from '@/components/ResultItems/AnalysesStatus.vue'
import StandardSamplesTable from '@/components/ResultItems/StandardSamplesTable/StandardSamplesTable'
import ExternalAnalysesStatus from '@/components/ResultItems/ExternalAnalysesStatus.vue'
import AnalysesUploadSection from '@/components/ResultItems/AnalysesUploadSection.vue'

export default {
  name: 'Samples',
  components: {
    Header,
    AnalysesStatus,
    ExternalAnalysesStatus,
    StandardSamplesTable,
    AnalysesUploadSection,
  },
}
</script>

<style lang="stylus" scoped>
  .Samples
    display block

  .PageContentWrap
    page-wrap-outer-1()
    padding-top 0

  .InnerWrap
    page-wrap-inner-2()
    overflow-x auto
</style>
