<template>
  <div
    v-if="externalSamplesStatus.isUsed"
    class="ExternalAnalysesStatus">
    <SectionHeadline :text="mixWB('ANALYZES_FROM_OTHER_PROJECTS')" />

    <div class="InnerWrap">
      <div class="InfoText">
        <span>
          {{ mixWB('SAMPLES_FROM_OTHER_PROJECTS_INFO_TEXT') }}
        </span>
      </div>

      <template
        v-for="project in externalSamplesStatus.externalProjects">
        <div
          :key="project.projectID"
          v-if="project.isUsed"
          class="TableWrap">
          <table>
            <tr>
              <th colspan="4">{{ mixWB('PROJECT') }}: {{ project.caseNumber }}</th>
            </tr>
            <tr>
              <th class="Status">{{ mixWB('STATUS') }}</th>
              <th class="SampleID">{{ mixWB('INTERNAL_SAMPLE_NO') }}</th>
              <th class="SampleID">{{ mixWB('EXTERNAL_SAMPLE_NO') }}</th>
              <th/>
            </tr>
            <ExternalAnalysesStatusRow
              v-for="sample in project.samples"
              :key="sample.equivalentNumber"
              :sampleItem="sample" />
          </table>
        </div>
      </template>

      <!-- Status -->
      <span
        class="StatusText"
        v-html="status.text"/>

      <p
        class="StatusInfoText"
        v-if="status.infoText">
        {{ status.infoText }}
      </p>

      <!-- Make report ready button -->
      <Button
        v-if="status.status === 'save-to-complete'"
        :text="mixWB('COMPLETE_REPORT')"
        @button-click="onSaveToCompleteClick" />

      <!-- Make report awaiting analyses button -->
      <Button
        v-if="status.status === 'no-longer-valid'"
        :text="mixWB('SET_AS_PENDING_ANALYZES')"
        @button-click="onSetToAwaitingAnalysesClick" />
    </div>
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import { mapGetters } from 'vuex'
import ExternalAnalysesStatusRow from '@/components/ResultItems/ExternalAnalysesStatusRow.vue'
import Button from '@/components/Buttons/Button.vue'

export default {
  name: 'ExternalAnalysesStatus',
  computed: {
    ...mapGetters([
      'standardSamplesStatus',
      'externalSamplesStatus',
      'currentScreeningData',
    ]),
    status() {
      const status = {
        status: '',
        text: `<b>${ this.mixWB('STATUS') }:</b> `,
        infoText: '',
      }

      // All internal and external analyses are completed, but we need to update the upload status

      /**
       * This would happen when all internal samples are done first, and any external samples
       * that were missing, now is completed.
       */

      if (
        !this.currentScreeningData.isTestDataUploaded
        && this.currentScreeningData.isCompleted
        && this.standardSamplesStatus.isCompleted
        && this.externalSamplesStatus.isCompleted
      ) {
        status.status = 'save-to-complete'
        status.text += this.mixWB('ANALYSES_FROM_OTHER_PROJECTS_PERFORMED')
        status.infoText = this.mixWB('CLICK_THE_BUTTON_BELOW_TO_COMPLETE_THE_REPORT')
        return status
      }

      // All internal analyses are completed but some external ones are missing
      // while the report is set to ready

      /**
       * This would happen when all internal analyses are done and all external ones were done,
       * but was changed so now this report is incomplete
       */

      if (
        this.currentScreeningData.isTestDataUploaded
        && this.currentScreeningData.isCompleted
        && this.standardSamplesStatus.isCompleted
        && !this.externalSamplesStatus.isCompleted
      ) {
        status.status = 'no-longer-valid'
        status.text += this.mixWB('EXTERNAL_SAMPLES_HAS_BEEN_MODIFIED_TEXT')
        status.infoText = this.mixWB('UPDATE_PROJECT_TO_AWAIT_ANALYSES_TEXT')
        return status
      }

      // None or not all analyses are completed
      if (!this.externalSamplesStatus.isCompleted) {
        status.status = 'missing'
        status.text += this.mixWB('SAMPLES_FROM_OTHER_PROJECTS_NEED_CLARIFICATION')
        status.infoText = this.mixWB('SAMPLES_NEED_CLARIFICATION_BEFORE_REPORT_COMPLETE')
        return status
      }

      // All external analyses are completed
      status.status = 'complete'
      status.text += this.mixWB('ANALYSES_FROM_OTHER_PROJECTS_PERFORMED')

      return status
    },
  },
  methods: {
    onSaveToCompleteClick() {
      this.$store.dispatch('updateScreening', { updateTestResultStatus: true })
    },
    onSetToAwaitingAnalysesClick() {
      this.$store.dispatch('updateScreening', { updateTestResultStatus: true })
    },
  },
  components: {
    SectionHeadline,
    ExternalAnalysesStatusRow,
    Button,
  },

}
</script>

<style lang="stylus" scoped>
  .ExternalAnalysesStatus
    width 100%
    margin-bottom 50px

  .InnerWrap
    width 100%
    max-width 600px

  .InfoText
    margin-bottom 10px

  .TableWrap
    width 100%
    margin-bottom 20px
    +below($tablet)
      overflow-x auto

  table
    width 100%
    min-width 500px

  .Status
    width 40px

  .SampleID
    width 70px

  .StatusInfoText
    font-style italic
    margin-top 5px
</style>

<style lang="stylus">
  .ExternalAnalysesStatus
    table,
    tr,
    th,
    td
      border-collapse collapse
      border 1px solid $color_grey_light
      font-size 0.875rem
      text-align left

    th,
    td
      padding 5px
      height 30px

    th
      background-color $color_grey_lighter

    tr
      &:nth-child(odd)
        background-color $color_grey_lightest
      &.Total
        border-top-width 2px
        td
          font-weight bold

    .Missing
      background-color $color_blue_lightest !important
    .Unintentional
      background-color $color_blue_lightest !important
    .Complete
      background-color lighten($color_primary_lightest, 50%) !important

</style>
