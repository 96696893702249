<template>
  <div class="AnalysesUploadSection">
    <SectionHeadline :text="mixWB('UPLOAD_ANALYSIS_RESULTS')" />

    <div
      class="LabWrap"
      v-if="validLabOptions.length > 1">
      <Select
        name="laboratoryID"
        :label="mixWB('LABORATORY')"
        :selectedID="selectedLabID"
        :options="validLabOptions"
        backgroundColor="white"
        @change="onLabSelectChange" />
    </div>

    <p>{{ mixWB('SAMPLE_PAGE_INTRO_1') }}</p>

    <div class="ButtonsWrap">
      <!-- Upload file -->
      <div
        class="UploadButtonWrap">
        <input
          ref="fileInput"
          type="file"
          accept=".xls,.xlsx"
          @change="onDataFileSelected" />
        <Button
          :text="mixWB('UPLOAD_ANALYSIS_RESULTS')"
          :isDisabled="isUploadDisabled"
          :fullWidth="false"
          size="small"
          @button-click="onUploadFileClick" />
      </div>

      <!-- Reset -->
      <Button
        size="small"
        :text="mixWB('RESET_ANALYZES')"
        :fullWidth="false"
        :isLoading="isResetLoading"
        :isDisabled="isResetDisabled"
        @button-click="onResetAnalyzesClick" />
    </div>

    <p
      class="OptionsInfoText"
      v-if="!currentScreeningData.isCompleted">
      {{ mixWB('ANALYSES_UPLOAD_ONLY_WHEN_PROJECT_COMPLETE') }}
    </p>

    <Dialog
      :isShowing="showResetErrorModal"
      :useComponent="NoficationModal"
      :componentProps="{
        texts: resetErrorTexts,
      }"
      @close="showResetErrorModal = false" />

    <Dialog
      :isShowing="!!analysesUploadStatus"
      :useComponent="TestDataUploadStatusModal"
      :componentProps="{
        uploadStatus: analysesUploadStatus,
      }"
      :easyClose="false"
      @close="onTestDataUploadStatusClose" />
  </div>
</template>

<script>
import SectionHeadline from '@/components/FormElements/SectionHeadline.vue'
import { mapGetters } from 'vuex'
import Select from '@/components/FormElements/Select.vue'
import Button from '@/components/Buttons/Button.vue'
import Dialog from '@/components/Modals/Dialog.vue'
import NoficationModal from '@/components/Modals/NoficationModal.vue'
import TestDataUploadStatusModal from '@/components/ResultItems/TestDataUploadStatusModal.vue'

export default {
  name: 'AnalysesUploadSection',
  data() {
    return {
      isResetLoading: false,
      selectedLabID: '',
      // Upload modal
      TestDataUploadStatusModal,
      // Reset error modal
      NoficationModal,
      showResetErrorModal: false,
      resetErrorTexts: [
        this.mixWB('ERROR_OCCURRED_PLEASE_TRY_AGAIN'),
        this.mixWB('CONTACT_SUPPORT_IF_THE_PROBLEM_PERSISTS'),
      ],
    }
  },
  computed: {
    ...mapGetters([
      'activeLaboratoriesAsArray',
      'analysesUploadStatus',
      'currentAccount',
      'currentScreeningData',
      'isDemoUser',
      'screeningTestResults',
      'standardSamplesStatus',
    ]),
    projectRequisitionData() {
      return this.currentScreeningData.requisition
    },
    validLabOptions() {
      return this.activeLaboratoriesAsArray.reduce((prev, lab) => {
        if (lab.id === 'milvaEuroFins') {
          return prev
        }

        prev.push({
          id: lab.id,
          text: this.mixWB(lab.translation),
        })
        return prev
      }, [])
    },
    isUploadDisabled() {
      if (this.isDemoUser) {
        return true
      }
      if (!this.currentScreeningData.isCompleted) {
        return true
      }
      if (!this.standardSamplesStatus.totals.required) {
        return true
      }
      return false
    },
    isResetDisabled() {
      return this.screeningTestResults.length === 0 || this.isDemoUser
    },
  },
  methods: {
    setDataOnLoad() {
      this.selectedLabID = this.projectRequisitionData.laboratoryID === 'milvaEuroFins'
        ? 'euroFins'
        : this.projectRequisitionData.laboratoryID
    },
    onLabSelectChange({ value }) {
      this.selectedLabID = value
    },
    onUploadFileClick() {
      this.$refs.fileInput.click()
    },
    onDataFileSelected(event) {
      const { files } = event.target

      if (!files || !files[0]) {
        return
      }

      this.$store.dispatch('uploadAnalyses', {
        file: files[0],
        labID: this.selectedLabID,
      })
    },
    onTestDataUploadStatusClose() {
      this.$store.dispatch('updateAnalysesUploadStatus', '')
      this.$refs.fileInput.value = ''
    },
    async onResetAnalyzesClick() {
      if (this.isResetLoading) {
        return
      }

      const answer = window.confirm(this.mixWB('ALL_ANALYSIS_ARE_REMOVED_CONTINUE_TEXT'))
      if (!answer) {
        return
      }

      this.isResetLoading = true

      try {
        await this.$store.dispatch('resetAnalyzes')
      }
      catch (error) {
        this.showResetErrorModal = true
      }

      this.$store.dispatch('updateScreening', { updateTestResultStatus: true })
      this.isResetLoading = false
    },
  },
  components: {
    SectionHeadline,
    Select,
    Button,
    Dialog,
  },
  created() {
    this.setDataOnLoad()
  },
}
</script>

<style lang="stylus" scoped>
  .AnalysesUploadSection
    display block

  .LabWrap
    max-width 300px
    margin-bottom 20px

  .ButtonsWrap
    display flex
    gap 10px
    margin-bottom 20px

  .UploadButtonWrap
    input
      display none

  .OptionsInfoText
    margin-bottom 20px
    margin-top -20px
    font-style italic
    color $color_grey
</style>
